<template>
  <div class="bg-neutral-100">
    <div
      class="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-24 lg:py-40"
    >
      <h2
        id="FAQs"
        class="text-2xl font-bold leading-10 tracking-tight text-gray-900"
      >
        Frequently asked questions
      </h2>
      <dl class="mt-10 space-y-8 divide-y divide-gray-900/10">
        <div
          v-for="faq in faqs"
          :key="faq.id"
          :id="faq.id.toString()"
          class="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
        >
          <dt
            class="text-xl font-bold text-center px-4 py-8 lg:py-16 leading-7 text-gray-100 lg:col-span-5 bg-primary-700 flex items-center justify-center"
          >
            <div>
              {{ faq.question }}
            </div>
          </dt>
          <dd class="mt-4 lg:col-span-7 lg:mt-0 flex items-center">
            <div class="text-base leading-7 text-neutral-800">
              <p v-html="faq.answer"></p>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      faqs: [
        {
          id: 1,
          question: "Why is RMR making this change?",
          answer:
            "<p>This change will bring participants a modern platform and greater conveniences, like instant claims processing, real-time claims reimbursements, and cards that have 'tap to pay' and digital wallet capabilities. With this change, we will be able to serve you even faster. This transition is a direct response to feedback we've received from our employers, employees, and brokers. With our new UMB relationship, we are able to lower many HSA investment & account fees and have more direct control over the HSA experience our participants receive.</p>"
        },
        {
          id: 2,
          question: "What will I need to do?",
          answer:
            "<p>We will take care of moving your account for you, but there are a few things you will need to do, like switch to a new payment card, mobile app and portal. If you have an HSA and wish to combine your two HSA accounts, you will also have to agree to an account transfer and liquidate any investments. We’ll keep you updated on deadlines and what to do.</p>"
        },
        {
          id: 3,
          question: "Is my health insurance also changing?",
          answer:
            "<p>No, this change is limited to your benefits spending accounts only: health savings accounts, flexible spending accounts, and all benefits administered by Rocky Mountain Reserve.</p>"
        },
        {
          id: 4,
          question: "When will this change happen?",
          answer:
            "<p>You will begin using the upgraded system and your new payment card <strong>this September</strong>.</p>"
        },
        {
          id: 5,
          question:
            "Will I need to create a new username and password for my new account?",
          answer:
            "<p>Yes, you will need to setup account credentials on the new portal. You can set up your new credentials and log in to your new account as soon as you receive your Welcome email from the new portal in Mid August. You will need your new payment card to finalize your registration on the portal, if you have a carded benefit with us.</p>"
        },
        {
          id: 6,
          question: "Is there a new mobile app?",
          answer:
            "<p>Yes, you will have a new mobile app: <strong>RMR Benefits</strong>. It will be available in both the <a style='color:blue;' target='_blank' rel='noreferrer' href='https://apps.apple.com/us/app/rmr-benefits/id6479498415'>Apple App Store</a> and the <a style='color:blue;' target='_blank' rel='noreferrer' href='https://play.google.com/store/apps/details?id=com.rockymountainreserve.rmr_app&hl=en_US'>Google Play Store</a>.</p>"
        },
        {
          id: 7,
          question: "How do I transfer my HSA?",
          answer:
            "<p>In <strong>mid-August</strong>, we will be opening a new HSA on our new portal. To combine your Blue Card HSA with your White Card HSA, please follow the transfer instructions sent to you from Rocky Mountain Reserve. The process to transfer will involve completing an electronic Consent to Transfer form, complete with e-signature. RMR will submit the form to the previous custodian on your behalf.</p>"
        },
        {
          id: 8,
          question:
            "What happens if I need to use my account while my funds are transferring?",
          answer:
            "<p>Your funds transfer window is very short, so in some cases, you may be able to hold your payments until the funds settle. Alternatively, you can use an alternative payment method and submit your expense for reimbursement after your funds are transferred.</p><br><p>Your HSA funds will remain available on your blue card unless you choose to transfer your funds.</p>"
        },
        {
          id: 9,
          question: "When will my existing RMR payment card be deactivated?",
          answer:
            "<p>Your existing blue payment card will be deactivated for FSA, HRA, and Parking/Transit accounts on <strong>9/1/24</strong>. Your card can still be used for HSA expenses.</p>"
        },
        {
          id: 10,
          question:
            "When will I receive my payment card, and when can I start using it?",
          answer:
            "<p>Your new card should arrive sometime between <strong>8/21/24</strong> and <strong>9/1/24</strong>. Activate it right away so it is ready when you need it. You can begin using it for eligible transactions beginning <strong>9/1/24</strong> or as assets are transferred. If you have multiple accounts, they will all be accessible from the same card. If you’ve moved recently, make sure your mailing address is up to date in the RMR system.</p>"
        },
        {
          id: 11,
          question: "What actions do I need to take for my HSA? ",
          answer:
            "<p>Continue to use your Blue Card HSA for HSA expenses until your Transfer of Assets begins, should you choose to combine your HSAs. If you choose not to transfer, and your Blue Card HSA funds are exhausted, begin using your White Card HSA after <strong>9/1/24</strong>.</p><br><p>Instructions to transfer will be communicated to HSA participants in email throughout the month of August.</p>"
        },
        {
          id: 12,
          question: "How do I liquidate my investments?",
          answer:
            "<p>Visit our dedicated page for step-by-step instructions and guidance on how to proceed. Click <a style='color:blue;' target='_blank' rel='noreferrer' href='https://www.rockymountainreserve.com/support/articles/29113579647379/'>here</a> to access the information you need.</p>"
        },
        {
          id: 13,
          question:
            "(WealthCare Saver) What if I do not want to transfer my HSA funds to my new account?",
          answer:
            "<p>If you choose not to transfer your Blue Card HSA to your New White Card HSA, you can continue to spend your HSA funds on your Blue Card HSA. If your funds are not exhausted or you do not transfer your funds by <strong>10/1/2024</strong>, the HSA Custodian will take charge of your HSA. They will begin to charge your account <strong>$3.95</strong> per month, unless your balance exceeds $5,000, in which the monthly fee will be $0.00.</p>"
        },
        {
          id: 16,
          question:
            "(UMB) What if I do not want to transfer my HSA funds to my new account?",
          answer:
            "<p>If you choose not to transfer your Blue Card HSA to your New White Card HSA, you can continue to spend your HSA funds on your Blue Card HSA. If your funds are not exhausted or you do not transfer your funds by <strong>10/1/2024</strong>, the HSA Custodian will take charge of your HSA. They will begin to charge your account <strong>$3.75</strong> per month, unless your balance exceeds $5,000, in which the monthly fee will be $0.00.</p>"
        },
        {
          id: 14,
          question: "(WealthCare Saver) Are there fees to transfer my HSA?",
          answer:
            "<p>If you transfer your HSA, your account will be charged an account closure fee of <strong>$25</strong> by the current custodian. Rocky Mountain Reserve will reimburse in full this fee.</p>"
        },
        {
          id: 15,
          question: "What is a quiet period?",
          answer:
            "<p>During the quiet period, access to your Rocky Mountain Reserve benefits accounts will be temporarily unavailable as we transfer administrative records to our new platform. Payroll deductions will continue as usual, with account access resuming once the quiet period ends. All deductions made during this time will be reflected accurately in your accounts post-transition.</p><br><p style='font-style:italic; font-size:small; font-weight:bold;'>*For HSA transfers there is a different quiet period that ranges from 2-6 weeks.</p>"
        },
        {
          id: 17,
          question: "Will my spouse or dependents be getting new cards?",
          answer:
            "<p>New cards will not automatically be mailed for spouses and/or dependents. If you want a card for a family member you can log into the portal and order a new card(s) for your family member(s).</p>"
        }
      ]
    };
  }
};
</script>
